.mintBarOuterContainer {
  display: flex;
  padding: 10px;
  justify-content: center;
  width: 100%;
}

.mintBarContainer {
  background: #5C5C5C;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  margin: 20px auto;
  position: relative;
  height: 35px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.publicSaleBar {
  height: 100%;
  transition: width 0.5s ease-in-out;
  border-radius: 25px 0 0 25px; 
  background: linear-gradient(45deg, #0098EA, #00D4FF); 
  min-width: 10px;
}

.mintInfo {
  text-align: center;
  font-size: 14px;
  color: white;
  position: absolute;
  width: 100%;
  line-height: 35px;
  z-index: 1;
  pointer-events: none;
}

@media (max-width: 768px) {
  .mintBarContainer {
    height: 25px;
    border-radius: 20px;
  }

  .publicSaleBar {
    border-radius: 20px 0 0 20px; 
  }

  .mintInfo {
    font-size: 12px; 
    line-height: 25px;
  }
}
