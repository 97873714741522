/* File: frontend/game/src/components/Common/LoadingView/style.module.css */
.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.nftImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
