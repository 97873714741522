/* File: frontend/mint/src/components/Footer/style.module.css */
.appFooter {
  margin-top: auto;
  text-align: center;
  padding: 1em 0;
  font-size: 0.8em;
}

.tonLink {
  color: #0098e9;  
  text-decoration: none;
}

.tonLink:hover {
  text-decoration: underline;  
}
