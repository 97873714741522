/* File: frontend/mint/src/components/Info/style.module.css */
.infoBlockContainer {
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.nftMedia {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.nftCardBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleText {
  font-size: 1.2em;
  padding: 40px;
  text-align: center;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.nftMediaContainer {
  width: 100%;
  max-width: 320px;
  margin: auto;
  transition: transform 0.5s ease-in-out;
}

.nftMediaContainer:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .titleText {
    padding: 30px 20px 40px 20px;
    font-size: 1.2em;
  }

  .nftMediaContainer {
    max-width: 70%;
  }
}
