.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  visibility: hidden;
}

.modalOverlay.active {
  opacity: 1;
  visibility: visible;
  backdrop-filter: blur(5px);
}

.modalContent {
  background-image: var(--background-gradient);
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.3s ease-out;
  transform: translateY(0);
}

.tonSymbol {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.darkTheme .counterButton,
.darkTheme .increaseFiveButton,
.darkTheme .decreaseFiveButton {
  color: var(--dark-text-color);
  transform: scale(1.1);
  transition: transform 0.2s ease;
}

.lightTheme .counterButton,
.lightTheme .increaseFiveButton,
.lightTheme .decreaseFiveButton {
  color: var(--light-text-color);
  transform: scale(1.1);
  transition: transform 0.2s ease;
}


@media (max-width: 768px) {
  .modalOverlay.active {
    align-items: flex-end;
  }
  .modalContent {
    height: 40%;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
}

.mintingTimeline {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
}

.counterContainer, .totalCost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.counterButton, .increaseFiveButton, .decreaseFiveButton {
  border: none;
  background-color: transparent;
  padding: 5px 15px;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
}

.confirmButton {
  padding: 12px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  background-color: #0098EA;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.2s;
}

.confirmButton:hover {
  background-color: #007acc;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

.mintCount {
  font-size: 1.4rem;
  padding: 0 20px;
  color: #007acc;
}

.totalCost {
  font-size: 1rem;
  padding-top: 40px;
  justify-content: center;
}
