/* File: frontend/mint/src/index.css */
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100;200;300;400;500;600;700;800&display=swap');

:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --header-color: #333333;
  --link-color: #0000EE;
  --font-size-large: 20px;
  --font-size-small: 16px;
  --black-bg-color: #00000;
  --light-bg-color: #ffffff;
  --dark-bg-color: #333333;

  --background-gradient-light: linear-gradient(135deg, #979797 0%, #FFFFFF 100%);
  --background-gradient-dark: linear-gradient(135deg, #2B2B2B 0%, #1A1A1A 100%);
  --background-gradient: var(--background-gradient-light);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Martian Mono', monospace;
}

body {
  color: var(--text-color);
  background-image: var(--background-gradient);
  user-select: none;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, h1, h2, h3, h4, h5, h6, a {
  font-family: 'Martian Mono', monospace;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--header-color);
}

a {
  color: var(--link-color);
}
