.mintSelectorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.totalCost {
  font-size: 20px;
  color: white;
  margin-top: 15px;
}

.costText, .tonSymbol {
  display: inline-block;
}

.tonSymbol {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.mintButton {
  padding: 12px 60px;
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 18px;
  background-color: #0098EA;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.2s;

  &:hover {
    background-color: #007acc;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
  }
}
