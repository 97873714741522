/* File: frontend/mint/src/components/NFTGrid/style.module.css */
:root {
  --grid-gap: 20px;
  --grid-padding: 20px;
  --card-border-color: #ddd;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --text-color: #666;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--grid-gap);
  padding: var(--grid-padding);
  /* min-height: 100vh; */
  justify-content: center;
  align-items: center;
}

.emptyState {
  text-align: center;
  font-size: 1.2em;
  color: var(--text-color);
  margin-bottom: 20px; /* Додає відступ між текстом і кнопкою */
}

.simpleButton {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #0098EA;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  max-width: 200px; /* Обмеження максимальної ширини */
  text-align: center;
  display: block; /* Забезпечення, що кнопка не розтягнеться на всю ширину */
  margin-left: auto;
  margin-right: auto;
}

.simpleButton:hover {
  background-color: #005f86;
  transform: translateY(-2px);
}


.centeredContainer {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}

.nftCard {
  border: 1px solid var(--card-border-color);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, filter 0.3s ease;
  cursor: pointer;
}

.nftCard:hover {
  filter: contrast(120%);
  transform: translateY(-5px);
}

.nftInfo {
  padding: 10px;
  flex-grow: 1;
}

.nftTitle, .nftDescription {
  color: var(--text-color);
}

.nftName {
  color: var(--text-color);
}

.nftTitle {
  margin: 0 0 10px 0;
  font-size: 1.2em;
}

.nftDescription {
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    padding: var(--grid-gap);
    gap: var(--grid-gap);
    padding-top: 100px;
  }
}