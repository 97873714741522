.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  visibility: hidden;
}

.modalOverlay.active {
  opacity: 1;
  visibility: visible;
  backdrop-filter: blur(10px);
}

.modalContent {
  width: 90%;
  max-width: 500px;
  height: auto;
  background-image: var(--background-gradient);
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  transition: transform 0.3s ease-out;
}

.contentGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.bottomGroup {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modalNftImage {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 0;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.nftDetails {
  text-align: left;
  padding: 20px 0;
  width: 100%;
}

.nftDescription {
  color: var(--text-color);
}

.nftName {
  color: var(--text-color);
  margin-bottom: 5px;
}

.revealTimeline {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
}


@media (max-width: 768px) {
  .modalOverlay.active {
    align-items: flex-end;
  }

  .modalContent {
    width: 100%;
    height: 70%;
    border-radius: 20px 20px 0 0;
  }

	.modalNftImage {
		width: 100%;
		max-width: 70%;
		height: auto;
		margin-top: 0;
		border-radius: 20px;
		box-shadow: 0 4px 8px rgba(0,0,0,0.3);
	}
	
}

.confirmButton {
  padding: 12px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  background-color: #0098EA;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.confirmButton:hover {
  background-color: #007acc;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}