/* File: frontend/mint/src/components/Header/style.module.css */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 1000;
  transition: transform 0.3s ease;
}

.socialIcons {
  display: flex;
  align-items: center;
}

.socialIcons a, .socialIcons img {
  margin-right: 20px;
  width: 35px; 
  height: 35px;
  transition: transform 0.3s ease;
}

.socialIcons a:last-child, .socialIcons img:last-child {
  margin-right: 0;
}

.socialIcons a:hover, .socialIcons img:hover {
  transform: scale(1.1);
}

.tonDisconnectButtonContainer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}

.toggleButton, .tonDisconnectButton {
  margin: 12px;
  padding: 7px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.toggleButton:hover, .tonDisconnectButton:hover {
  transform: scale(1.1);
}

.headerHidden {
  transform: translateY(-100%);
}

@media (max-width: 768px) {
  .socialIcons {
    padding-top: 8px;
    padding-left: 20px;
  }
  
  .socialIcons a, .socialIcons img {
    margin-right: 10px;
    width: 38px;
    height: 38px;
  }
}
