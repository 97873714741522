/* File: frontend/mint/src/components/UI/Buttons/style.module.css */
.button {
  padding: 16px 50px;
  border-radius: 5px;
  border: none;
  background-color: #0098EA;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  width: 100%;

  &:hover {
    background-color: #388e3c;
    transform: translateY(-2px);
  }
}

@media (max-width: 768px) {
  .button {
    padding: 12px 30px;
    font-size: 14px;
  }
}
