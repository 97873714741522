/* File: frontend/mint/src/components/NFTGrid/MediaElement/style.module.css */
.nftImage {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.nonInteractive {
  pointer-events: none;
}

@media (max-width: 768px) {
  .nftImage {
    width: 100%;
    aspect-ratio: 1 / 1;
  }
}